//== Colors
//

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$font-family-sans-serif:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-brand:       Garamond, Georgia, "Times New Roman", Times, serif;
$font-family-system:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

//## Prices and Promo Text Appearance

//** Color for promo (default is bright green: #008a00)
$brand-promo: #eb8526 !default;
$brand-primary: $primary;

//** Price Text appearance (for readability defaults to Arial 16px bold 700)
$price-font-family: $font-family-system;
$price-font-size: 1.25rem;
$price-font-weight: 700;

//** Color for order total (default is bright red #b12704)
$price-total-color: #b12704;

// Headings

// $headings-font-family:    $font-family-serif;
$headings-font-weight:    700;
$headings-line-height:    1.1;
$headings-color:          $brand-primary;



//
// Boostrap references
$bootstrapContainerPadding: 15px;

$favoriteBtnWithText: false;

// Boostrap z-index theme settings:
$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// $enable-caret: true !default;
// $enable-rounded: true !default;
// $enable-shadows: false !default;
// $enable-gradients: false !default;
// $enable-transitions: true !default;
// $enable-prefers-reduced-motion-media-query: true !default;
// $enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
// $enable-grid-classes: true !default;
// $enable-pointer-cursor-for-buttons: true !default;
// $enable-print-styles: true !default;
// $enable-responsive-font-sizes: false !default;
// $enable-validation-icons: true !default;
// $enable-deprecation-messages: true !default;
$enable-caret: true !default;


// Components
//
// Define common padding and border radius sizes and more.

// $line-height-lg: 1.5 !default;
// $line-height-sm: 1.5 !default;

// $border-width: 1px !default;
// $border-color: $gray-300 !default;

// $border-radius: .25rem !default;
// $border-radius-lg: .3rem !default;
// $border-radius-sm: .2rem !default;

// $rounded-pill: 50rem !default;

// $box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;
// $box-shadow: 0 .5rem 1rem rgba($black, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// $component-active-color: $white !default;
// $component-active-bg: theme-color("primary") !default;

// $caret-width: .3em !default;
// $caret-vertical-align: $caret-width * .85 !default;
// $caret-spacing: $caret-width * .85 !default;

// $transition-base: all .2s ease-in-out !default;
// $transition-fade: opacity .15s linear !default;
// $transition-collapse: height .35s ease !default;

$border-radius: .2rem !default;
$border-radius-lg: .25rem !default;
$border-radius-sm: .15rem !default;



// Typography
//
// Font, line-height, and color for body text, headings, and more.

// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base: $font-family-sans-serif !default;

// $font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
// $font-size-lg: $font-size-base * 1.25 !default;
// $font-size-sm: $font-size-base * .875 !default;

// $font-weight-lighter: lighter !default;
// $font-weight-light: 300 !default;
// $font-weight-normal: 400 !default;
// $font-weight-bold: 700 !default;
// $font-weight-bolder: bolder !default;

// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;

// $h1-font-size: $font-size-base * 2.5 !default;
// $h2-font-size: $font-size-base * 2 !default;
// $h3-font-size: $font-size-base * 1.75 !default;
// $h4-font-size: $font-size-base * 1.5 !default;
// $h5-font-size: $font-size-base * 1.25 !default;
// $h6-font-size: $font-size-base !default;

// $headings-margin-bottom: $spacer / 2 !default;
// $headings-font-family: null !default;
// $headings-font-weight: 500 !default;
// $headings-line-height: 1.2 !default;
// $headings-color: null !default;

// $display1-size: 6rem !default;
// $display2-size: 5.5rem !default;
// $display3-size: 4.5rem !default;
// $display4-size: 3.5rem !default;

// $display1-weight: 300 !default;
// $display2-weight: 300 !default;
// $display3-weight: 300 !default;
// $display4-weight: 300 !default;
// $display-line-height: $headings-line-height !default;

// $lead-font-size: $font-size-base * 1.25 !default;
// $lead-font-weight: 300 !default;

// $small-font-size: 80% !default;

// $text-muted: $gray-600 !default;

// $blockquote-small-color: $gray-600 !default;
// $blockquote-small-font-size: $small-font-size !default;
// $blockquote-font-size: $font-size-base * 1.25 !default;

// $hr-border-color: rgba($black, .1) !default;
// $hr-border-width: $border-width !default;

// $mark-padding: .2em !default;

// $dt-font-weight: $font-weight-bold !default;

// $kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25) !default;
// $nested-kbd-font-weight: $font-weight-bold !default;

// $list-inline-padding: .5rem !default;

// $mark-bg: #fcf8e3 !default;

// $hr-margin-y: $spacer !default;

$font-size-base: 0.95rem !default;
$line-height-base: 1.4 !default;
$h1-font-size: $font-size-base * 1.9 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.2 !default;
$h4-font-size: $font-size-base * 1.05 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base * 0.85 !default;





//
//
// Custom Variables

$goldenRatio: 1.618;

$bootstrapContainerPadding: 15px;

$totalsFirst: true !default;
$hilightTotal: true !default;
$hilightLastAddedProduct: true !default;
$hilightBasketProductOnHover: false !default;
$hideInactivePromoPrice: true !default;
$conditionsBetweenBrakets: true !default;
$enableInnerShadow: true !default;
$basketContentInnerShadow: 0px -35px 14px -31px rgba(0, 0, 0, 0.1);
$enableAlternateBackgrounds: true !default;
$displayMemberIcon: false !default;
$hasConceptProducts: true !default;

$voidFlex: 2%;
$voidDefault: .85em;
$voidNarrow: $voidDefault / 2;
$voidTiny: $voidDefault / 5;
$lineHeight: 21px;
$wordSpacing: var(--word-spacing);

$colorPrimary: var(--color-primary);

$separatorColor: var(--color-gray-light);
$separatorBgColor: var(--color-gray-lighter);

$priceFontFamily: $price-font-family;
$priceFontSize: var(--font-size-medium);
$priceBigFontSize: var(--font-size-big);
$priceFontWeight: $price-font-weight;
$priceBold: 700;
$priceLegendFallbackColor: #999;
$priceLegendColor: var(--color-gray);
$promoColor: var(--color-group-promo);
$promoColorComplementary: var(--color-white);
$promoFontSize: var(--font-size-promo);
$fallbackPromoColor: #777;
$fallbackPromoColorComplementary: #fff;
$labelFontSize: var(--font-size-label);
$lastAddedBgColor: var(--color-gray-lighter);
$highlightOnHoverFallbackColor: #eee;
$highlightOnHoverColor: var(--color-gray-lighter);

$borderWidth: 1px;
$borderStyle: solid;
$borderColor: #ccc;
$borderDefault: $borderWidth $borderStyle $borderColor;
$borderTransparent: $borderWidth $borderStyle transparent;

$basketDefaultBgColor: #fff;

$basketBodyScrollBarWidth: 18px;

$basketHeaderPadding: 6px;
$basketHeaderFontSize: 12px;
$basketHeaderHeightCalculated: ($basketHeaderPadding*2)+($basketHeaderFontSize + $borderWidth);

$basketFooterFallbackBgColor: #efefef;
$basketFooterBgColor: var(--color-gray-lighter);

$desktopFontSize: var(--font-size-base);
$desktopFontSizeSmall: var(--font-size-small);
$desktopFontSizeMedium: var(--font-size-medium);
$desktopFontSizeBig: var(--font-size-big);
$desktopPriceFontSize: var(--font-size-big);

$headingsFontFamily: Cabin, "Helvetica Neue", Helvetica, Arial, system-ui, sans-serif;
$headingsLineHeight: 125%;
$headingsFontWeight: 700;

@mixin heading-style {
    font-family: $headingsFontFamily;
    line-height: $headingsLineHeight;
    font-weight: $headingsFontWeight;
}

$phoneFontSize: var(--font-size-base);
$phoneFontSizeSmall: var(--font-size-small);
$phoneFontSizeBig: var(--font-size-medium);
$phonePriceFontSize: var(--font-size-medium);
$desktopModalContentHeight: 65vh;
$phoneModalContentHeight: 55vh;
$phoneLandscapeModalContentHeight: 45vh;

// Boostrap z-index theme settings:
$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;