:root {
    --color-white: #fff;
    --color-red: #990524;
    --color-blue: #000e72;
    --color-green: #008a00;
    --color-green-dark: #00543c;
    --color-orange: #ea8c2c;
    --color-yellow: #ffee00;
    --color-gray: #a19e97;
    --color-gray-light: #ccc;
    --color-gray-lighter: #eee;
    --color-gray-dark: #4a4c4b;
    --color-gray-darker: #313332;

    --color-group-promo: var(--color-orange);

    --font-size-base: calc(10px + .4vmax);
    --font-size-big: calc(var(--font-size-base) * 1.75);
    --font-size-medium: calc(var(--font-size-base) * 1.2);
    --font-size-small: calc(var(--font-size-base) * .8);
    --font-size-label: calc(var(--font-size-base) * .9);
    --font-size-promo: var(--font-size-base);
}